
.product-weekly-report {
  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      tr {
        &.top-header {
          th {
            border-left: 10px solid var(--body-bg);
            &:first-child {
              border-left: none;
            }
          }
        }
      }

      th {
        white-space: nowrap;
        vertical-align: middle;
        width: 100%;
        background-color: var(--table-tr-bg);

        &:first-child {
          position: sticky;
          left: 0;
          width: fit-content;
        }
        &:not(:first-child) {
          text-align: right;
          padding: 0.85rem 0;
        }
      }
    }

    td {
      &:not(:first-child) {
        text-align: right;
        padding: 0.85rem 0;
      }
      &.total {
        max-width: 400px;
        white-space: break-spaces;
      }
      &:first-child {
        position: sticky;
        left: 0;
        background-color: var(--table-tr-bg);
      }
    }

    th, td {
      .td-row {
        display: flex;
        justify-content: center;
        border-right: 1px #98a6ad solid;
        padding: 0 5px;

        &.none-line {
          border-right: none;
        }
        .td-row-left {
          text-align: end;
          width: 100%;
        }
        .td-row-right {
          text-align: start;
          vertical-align: top;
          color: #98a6ad;
          font-size: 10px;
          margin-left: 2px;
          width: 50px;
        }
      }
    }
  }

  /deep/ .product-box {
    .img-block {
      height: 118px;
    }
  }

  .block-actions {
    margin-top: 10px;
  }
}
